"use strict";

import {onFind} from "@elements/init-modules-in-scope";
import {on} from "@elements/dom-utils";

export function init () {
    onFind('.js-email-link', function (link) {
        on('click', function () {
            link.setAttribute('href', 'mailto:' + link.getAttribute('data-name') + '@' + link.getAttribute('data-domain'))
        }, link);
    });
}