"use strict";
import {on, toggleClass, hasClass, addClass, removeClass, closest, find, findAll, findAllIn} from "@elements/dom-utils";

export function init () {
    const isMobile = () => matchMedia('(max-width: 767px)').matches;

    let mainNav = findAll('.js-simple-nav');

    mainNav.forEach(function (item) {
        let $nav = item,
            $toggle = find('.js-simple-nav__toggle', $nav),
            $collapse = find('.js-simple-nav__collapse', $nav);

        on('click', function () {
            toggleClass('is-open', $toggle);
            toggleClass('is-open', $collapse);
        }, $toggle);

        findAll('.js-simple-nav__list').forEach(function (list) {
            let $listItems = findAllIn('.js-simple-nav__list-item', list);
            let $itemToggles = findAllIn('.js-simple-nav__list-toggle', list);

            $itemToggles.forEach(function (toggle) {
                on('click', function () {
                    let $parentItem = closest('.js-simple-nav__list-item', this);
                    if (hasClass('is-open', $parentItem)) {
                        removeClass('is-open', $parentItem);
                    } else {
                        if (isMobile()) {
                            addClass('is-open', $parentItem);
                        } else {
                            $listItems.forEach(function (item) {
                                removeClass('is-open', item)
                            });
                            addClass('is-open', $parentItem);
                        }
                    }
                }, toggle);
            });
        });
    });
}
