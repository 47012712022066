// import Modal from 'bootstrap.native/dist/components/modal-native.esm';
// document.querySelectorAll('[data-toggle="modal"]').forEach(function (element) {
//     new Modal(element);
// });

// import Dropdown from 'bootstrap.native/dist/components/dropdown-native.esm';
// document.querySelectorAll('[data-toggle="dropdown"]').forEach(function (element) {
//     new Dropdown(element);
// });

import './scripts/common';

if (!window.HTMLPictureElement) {
    import('picturefill');
}

let _config = window['_config'] || {};

let app = {
    debug: false,
};
window.app = app || {};

/**********************************
 *
 *      Project Stuff
 *
 * ********************************/

import * as searchBar from './scripts/search-bar';
searchBar.init();

import * as fetchModal from './scripts/fetch-modal';
fetchModal.init();