"use strict";

import {find,addClass,removeClass} from "@elements/dom-utils";

export function init () {

    let scrollPos = window.scrollY,
        offset = matchMedia('(max-width: 767px)').matches ? 60 : 69;

    const nav = find('.js-affix-nav');
    const navHeight = nav.offsetHeight;
    const navPlaceholder = find('.js-affix-nav-placeholder');

    const addClassOnScroll = () => addClass('is-affix', nav);
    const removeClassOnScroll = () => removeClass('is-affix', nav);

    navPlaceholder.style.minHeight = nav.getBoundingClientRect().height.toString() + "px";

    window.addEventListener('scroll', function() {
        scrollPos = window.scrollY;

        if (scrollPos === 0) {
            removeClassOnScroll();
        } else {
            if (scrollPos >= navHeight - offset) {
                addClassOnScroll();
            } else {
                removeClassOnScroll();
            }
        }
    });

}
