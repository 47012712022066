"use strict";

import {onFind} from "@elements/init-modules-in-scope";
import {hasClass, removeClass, addClass, on, closest, findAll, findAllIn} from "@elements/dom-utils";
import asyncAppend from "@elements/async-append";
import fetch from '@elements/fetch';
import 'url-search-params-polyfill';
import formDataEntries from 'form-data-entries';
import {activateScrollLock, deactivateScrollLock} from "@elements/scroll-lock";

export function init () {
    onFind('.js-search-bar', function ($searchBar) {
        let url = $searchBar.getAttribute('data-filter-url');

        on('change', function () {
            let formEntries = getFormDataEntries([$searchBar]);
            let params = new URLSearchParams(formEntries);

            asyncAppend({
                target: findAll('.js-search-bar-request__result'),
                loading: findAll('.js-search-bar__loading')
            }, fetch(url, {
                body: params
            }));
        }, $searchBar)
    });

    onFind('.js-search-bar__toggle', function ($toggleButton) {
        on('click', function () {
            let $parent = closest('.js-search-bar', $toggleButton);

            if (hasClass('is-open', $parent)) {
                removeClass('is-open', $parent);

                if (matchMedia('(max-width: 767px)').matches) {
                    deactivateScrollLock();
                }
            } else {
                addClass('is-open', $parent);

                window.addEventListener("click", function _listener(evt) {
                    if (!$parent.contains(evt.target)) {
                        removeClass('is-open', $parent);

                        window.removeEventListener("click", _listener, true);
                    }
                }, true);

                if (matchMedia('(max-width: 767px)').matches) {
                    activateScrollLock();
                }
            }

        }, $toggleButton);
    });
}

function getFormDataEntries(forms) {
    let formDataArray = [];

    forms.map(form => {
        // unchecked checkboxes and radios needs to be added manually to formDataArray
        let selectors = findAllIn('input[type="radio"], input[type="checkbox"]', form);
        let selectorNames = [];

        selectors.map(function(selector) {
            selectorNames.push(selector.name);
        });

        for (let pair of formDataEntries(form)) {
            formDataArray.push(pair);
        }

        let existingNames = formDataArray.map(entry => entry[0]);
        selectorNames = [...new Set(selectorNames)];

        selectorNames.forEach(function (name) {
            let newEntry = [name, ""];
            if(!existingNames.includes(name)) {
                formDataArray.push(newEntry);
            } else {
                formDataArray.filter(item => item !== newEntry);
            }
        });
    });

    return formDataArray;
}