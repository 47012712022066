import {onFind} from "@elements/init-modules-in-scope";
import {on} from "@elements/dom-utils";
import {setServicePermission} from "@elements/cookie-permissions";

const selectors = {
    btn: '.js-accept-cookie-service'
};

export function init () {
    onFind(selectors.btn, function (baseElement) {
        let cookieService = baseElement.dataset.cookieServiceOverlayName || false;

        if(cookieService) {
            on('click', function() {
                setServicePermission(cookieService, true);
            }, baseElement);
        } else {
            console.warn('cookie-service-overlay: no cookie service name found!')
        }
    });
}

