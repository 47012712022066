"use strict";

import {findIn, addClass, removeClass} from "@elements/dom-utils"
import {onFind} from "@elements/init-modules-in-scope";
import {registerPermissionGrantedCallback} from "@elements/cookie-permissions";


export function init() {
    registerPermissionGrantedCallback('googleMap', function () {
        onFind('.js-google-map', function (mapElement) {
            addClass('d-none', findIn('.js-accept-cookie-service', mapElement).parentElement);

            let iframe = findIn('.embed-responsive-item', mapElement);
            removeClass('bg-light-grey', mapElement);

            iframe.src = iframe.dataset.iframeSrc;
        });
    });
}