"use strict";

import {findAll, on, closest, addClass, removeClass, findIn} from "@elements/dom-utils";

export function init () {
    let videos = findAll('.js-video');

    videos.forEach((videoContainer) => {
        let video = findIn('.js-video__video', videoContainer),
            playButton = findIn('.js-video__play', videoContainer) || closest('.js-video__play', videoContainer) || video;

        if (video && playButton) {
            on('playing', function () {
                addClass('is-playing', videoContainer);
            }, video);

            on('pause', function () {
                removeClass('is-playing', videoContainer);
            }, video);

            on('ended', function () {
                removeClass('is-playing', videoContainer);
            }, video);

            on('click', () => video.play(), playButton);
        }
    });

}